@import "src/client_customizations/scss/client_master.scss"; 

.PricePanels {
	background-color: $PricePanels-background-color;
	border: none;
	.Nav {
		border-bottom: $PricePanels-Nav-border;
		margin: 0;
		padding: 0;
	}
	li {
		a {
			font-weight: $PricePanels-li-a-font-weight;
			font-size: $PricePanels-li-a-font-size;
			color: $PricePanels-li-a-font-color;
			cursor: pointer;
			margin-top: 16px;
			margin-bottom: 10px;
			&:hover {
				text-decoration: none;
			}
		}
		a.active {
			span {
				padding-bottom: 16px;
				border-bottom: $PricePanels-li-a-span-border_active;
			}
		}
	}
	.nav-pills {
		.nav-link.active {
			background-color: $PricePanels-nav-pills-nav-link-background-color_active;
			color: $PricePanels-nav-pills-nav-link-font-color_active;
		}
	}
	.bottom-margin-large {
		margin-bottom: 30px;
		border: none; 
	}
	.bottom-margin-small {
		margin-bottom: 10px;
	}
	.no-border {
		border: none !important;
	}
	.tab-pane {
		padding: 0;
		.PanelItem {
			// border-top: $PricePanels-tab-pane-PanelItem-border;
			padding: 15px;
		}
		.PanelItemTop {
			display: inline-block;
			text-align: left;
		}
	}
	 .inventory {
		display: flex;
		justify-content: center;
		a{
			width: 100%;
		}
        button {
			width: 100%;
            background: white;
			border: none;
			color: #477A06;
			border-radius: 6px;
			border: 2px #E4E4E4 solid !important;
			margin-bottom: 10px;
			padding: 1rem 6.5rem;
			font-size: 16px;
			font-weight: 600;
            }
        }
    .update {
		display: flex;
		justify-content: center;
        button {
            background-color: white;
			color: #07783D;
			border: 1px solid #07783D;
			border-radius: 11px;
			margin-bottom: 10px;
			padding: 1rem 5rem
            }
        }
}
.MatchScoreBadge {
	text-align: center;
	background-color: $MatchScoreBadge-background-color;
	padding: 5px;
	border: $MatchScoreBadge-border;
	margin-top: 15px;
}