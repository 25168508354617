@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
	margin-bottom: 15px;
	label {
		font-weight: $EVsSort-label-font-weight;
		font-size: $EVsSort-label-font-size;
		color: $EVsSort-label-color;
		letter-spacing:$EVsSort-label-letter-spacing;
		line-height: $EVsSort-label-line-height;
	}
	select {
		// background: $EVsSort-select-background;
		border: $EVsSort-select-border;
		// border-radius:$EVsSort-select-border-radius;
		font-size: $EVsSort-select-font-size;
		color: $EVsSort-select-color;
		letter-spacing: $EVsSort-select-letter-spacing;
	}
}

.evsToggle {
	margin-bottom: 1rem;
	text-align: center;
}

.rangeToggle {
	text-align: center;
}

.renderToolTip {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tooltipEvName {
	color:#0072CE;
	border-bottom: solid 5px #d9d9d9;
	font-size:18px;
}

.tooltipEvName:hover{
	text-decoration:none;
}

.tooltipTextContainer {
	display:flex;
	flex-direction: column;
	border: solid 2px #F5F5F5; 
	padding: 8px;
	text-decoration:none;
}

.tooltipDescription {
	display:flex;
	flex-direction: column;
	p {
		font-size: 18px;
		margin-bottom: 0px;
	}
}

.lead {
	padding-top:12px;
}

.evsToggle {
	padding-bottom: 20px;
}

.mobileText {
	margin: 15px;
}

.evs-toggle-image {
	padding-right: 8px;
}

.toggle-btn{
	padding: 10px 40px;
	font-size: 18px;
	border-radius: 0;
	border: 0;
	color: $darkBlue;
	font-weight: 500;
	background-color: #fff;
	border: 1px solid $darkBlue;
	vertical-align: middle;
	margin-left: 2px;
	margin-right:2px;
	.evs-toggle-image-hover {
		display:none;
	}
	&:hover {
		border-radius: 0;
		color: #fff;
		font-weight: 500;
		text-decoration: none;
		background-color: $darkBlue;
		.evs-toggle-image-passive {
			display:none;
		}
		.evs-toggle-image-hover {
			display:inline;
		}
	}
}

.toggle-btn-active{
	padding: 10px 40px;
	font-size: 18px;
	border-radius: 0;
	border: 0;
	color: #fff;
	font-weight: 500;
	background-color: $darkBlue;
	border: 1px solid $darkBlue;
	vertical-align: middle;
	margin-right: 2px;
	margin-left: 2px;
}

@media (min-width: 576px) {
	.EVsSort {
		float: right;
	}
}

.sort-by-row {
	margin-top:-50px;

	@media(max-width: 991px) {
		margin-top:0;
	}
}

.ev-container-row {
	margin-top:50px;
}

.MatchScoreOptions {
	p.h2 {
		font-size:15px;
		margin:0 0 35px 0;
	}

	.form-group {
		margin:0 0 25px 0 !important;

		label {
			margin:0 0 10px 0;
			color:#2B2B2B;
			font-size:10px;
		}

		p.mb-0 {
			margin:15px 0 0 !important;
			color:#73767B;
		}
	}

	.btn {
		max-width: 90%;
		background-color:transparent;
		color:#4D8406;
		text-transform: none !important;
		border:none;
		padding:0;
		position: relative;
		text-align: left;

		&:after {
			content: "";
			width:15px;
			height:10px;
			display:block;
			position: absolute;
			top:4px;
			right:-20px;
			background-repeat: no-repeat;
			background-size:7px auto;
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMC4yNzA4OTQgMC4yNTYzMTlDMC42MzIwODUgLTAuMDg1NDM5NiAxLjIxNzY5IC0wLjA4NTQzOTYgMS41Nzg4OCAwLjI1NjMxOUw3LjEyODIyIDUuNTA3MDhDNy40ODk0MSA1Ljg0ODgzIDcuNDg5NDEgNi40MDI5NCA3LjEyODIyIDYuNzQ0NjlMMS41Nzg4OCAxMS45OTU1QzEuMjE3NjkgMTIuMzM3MiAwLjYzMjA4NSAxMi4zMzcyIDAuMjcwODk0IDExLjk5NTVDLTAuMDkwMjk3OSAxMS42NTM3IC0wLjA5MDI5NzkgMTEuMDk5NiAwLjI3MDg5NCAxMC43NTc4TDUuMTY2MjMgNi4xMjU4OUwwLjI3MDg5NCAxLjQ5MzkzQy0wLjA5MDI5NzkgMS4xNTIxOCAtMC4wOTAyOTc5IDAuNTk4MDc3IDAuMjcwODk0IDAuMjU2MzE5WiIgZmlsbD0iIzY0QTcwQiIvPgo8L3N2Zz4K")
		}
	}
}

.filter-controls {
	p.h2 {
		font-size:15px;
		margin:0 0 35px 0;
	}

	span.label-style {
		margin:0 0 10px 0;
		color:#2B2B2B;
		font-size:10px;
		display: block;
	}

	.form-group {
		margin:0 0 25px 0 !important;

		label {
			margin:0 0 10px 0;
			color:#2B2B2B;
			font-size:10px;
		}

		p.mb-0 {
			margin:15px 0 0 !important;
			color:#73767B;
		}

		.btn-grid-container {
			.btn {
				background-color: #fff;
				border:1px solid #B0B0B0;
				color:#4D8406;
			}
		}
	}

	.filter-switch {
		margin:10px 0;

		span.badge {
		    img {
			 width:25px;
		    }
		}
  
		.switch {
		    float:right;
		}
  
		.clearfix {
		    clear:both;
		}
	   }
}