@import "src/client_customizations/scss/client_master.scss";


#VehicleTypeFilter {
    .filter-btns {
        display: flex;
    }
    .ev-filter-btn {
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        border: none;
        padding: 15px 25px;
        cursor: pointer;
        width: 100%;
        outline:none;
    }
    .last {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-bottom: 10%;
    }
    .middle {
        border-radius: 0px;
    }
    .first {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .buttonActiveStyle {
        background: #4D8406;
        color: white;
    }
    .buttonInactiveStyle {
        color: black;
        background-color: white;
    }
}