

#ev-jumbotron-title {
       background-color:#f2f2f2;
       padding:50px 0 25px;

       h1 {
              font-size:35px;
       }
}

.PricePanels {
       .tab-content {
              .PanelItem {
                     .PanelItemTop {
                            p.RenderItemTitle {
                                   text-align: center;
                                   color:#666666;
                            }

                            p.h1 {
                                   font-size:30px;
                                   color:#333333;
                            }
                     }

                     p.h2 {
                            font-size:20px;
                     }
              }
       }
}

.price-panel-links {
       .col {
              display:flex;
              justify-content: space-between;
              
              a {
                     width:90%;
                     background-color:#fff;
                     color:#477A06;
                     border: 1px solid #DBDBDB;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     border-radius: 3px;
                     min-height: 50px;
                     font-size:15px;

                     &:last-child {
                            float:right;
                     }
              }
       }
}

.show-full-list-container {
       text-align: left;

       button {
              position: relative;
              color:#477A06;
              padding: 10px 40px 10px 30px;
              border: 1px solid #DBDBDB;

              &:after {
                     content:"";
                     width:9px;
                     height:9px;
                     background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjNjRBNzBCIi8+Cjwvc3ZnPgo=");
                     background-repeat: no-repeat;
                     display:block;
                     position: absolute;
                     top: 50%;
                     transform: translateY(-50%);
                     right:18px;
                 }
       }

       .CalcPanels {
              .btn {
                     color:#4D8406;
                     background-color:#fff;

                     &.active {
                            border:2px solid #4D8406;
                     }
              }
       }
}

#CostOfOwnership {
       background-color:#F2F2F2;
       padding:50px 0;

       .cost-ownership-title {
              margin:50px 0;

              p.h1 {
                     position: relative !important;
                     top:0 !important;
              
                     span {
                            color:#477A06;
                     }
              }

              a {
                     text-align: center;
                     width: 100%;
                     display: block;
              }
       }
}

.monthly-fuel-cost-chart, .cost-to-own-comparison-chart, .BreakevenChart {
       p.graph-sub-title {
              font-size:17px;
       }

       .show-full-list-container {
              text-align: center;
              margin:20px 0 0;

              button {
                     background-color:#fff;
              }
       }
}

.cost-of-ownership-options {
       p.h2 {
		font-size:15px;
		margin:0 0 35px 0;
	}

	.form-group {
		margin:0 0 25px 0 !important;

		label {
			margin:0 0 10px 0;
			color:#2B2B2B;
			font-size:10px;
		}

		p.mb-0 {
			margin:15px 0 0 !important;
			color:#73767B;
		}
	}
}

#maps-section {
       padding:100px 0;

       h1 {
              text-align: center;
              font-size:35px;
              margin:0 0 40px;
       }
}