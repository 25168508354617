@font-face {
       font-family: 'sharp sans';
       src: url('./client_customizations/assets/fonts/SharpSansDispNo1-Medium.ttf') format('truetype');
       font-weight: 500;
}

@font-face {
       font-family: 'sharp sans';
       src: url('./client_customizations/assets/fonts/SharpSansDispNo1-Semibold.ttf') format('truetype');
       font-weight: 600;
}