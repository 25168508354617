/* EV Page */
.ev-page {
  h1 {
    text-align: center;
    font-size: 45px;
    font-weight: 100;
    margin: 20px 0;
  }

  .divider {
    width: 115px;
    height: 4px;
    background-color: #6ca943;
    background-image: linear-gradient(90deg, #6ca943, #03783d);
    display: block;
    margin: 0 auto 20px;
  }

  .btn-ae {
    background-color: #6da943 !important;
    border-radius: 5px;
    padding: 10px 40px;
  }

  .input-well {
    border: 1px solid #e5e5e5;
    border-radius: 0px;

    label {
      font-weight: 500;
      color: #2b2b2b;
    }

  }

  .MatchScoreOptions {
    p.h2 {
      margin-bottom: 25px;
    }

    &.input-well {
      .btn {
        position: relative;
        background-color: transparent;
        border: none;
        padding: 0px;
        color: #4D8406;
        font-size: 14px;

        &:after {
          content: "\203A";
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
  }

  .ev-filter-controls {
    p.h2 {
      margin-bottom: 25px;
    }

    .filter-switch {
      span.badge {
        img {
          width: 25px;
        }
      }

      .switch {
        float: right;
      }

      .clearfix {
        clear: both;
      }
    }

    .btn-grid-container {
      .btn {
        background-color: transparent;
        color: #333333;
        text-align: center;
      }
    }
  }
}

/* EV Detail Page */
.ev-jumbotron {
  background-color: #f2f2f2 !important;
  max-width: none;

  h1 {
    font-size: 35px;
  }

  .PricePanels {
    li a {
      margin-bottom: 13px;

      span {
        font-weight: 600;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    li a.active span {
      border-bottom: 7px solid #6da943;
    }

    .PanelItem {
      &.text-center {
        .PanelItemTop {
          p.RenderItemTitle {
            text-align: center;
            letter-spacing: 1.5px;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
          }

          p.h1 {
            font-weight: 700;
            text-align: center;
            font-size: 40px;
          }
        }
      }

      p.h2 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 25px;
      }

      .btn {
        font-size: 12px;
        padding: 0px;
        border: none;
        color: #03783d;
        font-weight: 700;
      }
    }
  }

  .ev-pricepanel-buttons {
    display: flex;
    justify-content: space-between;
  }

  .btn {
    background-color: #fff;
    font-size: 13px;
    padding: 0px;
    color: #03783d;
    font-weight: 700;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 5px;
  }
}

/* Tabs */
.tab-section {
  padding-bottom: 20px;
  max-height: 150px;
  overflow: hidden;

  @media (max-width: 767px) {
    max-height: 200px;
  }

  &.active {
    max-height: none;
    overflow: visible;

    .tab-header {
      h1 {
        color: #000000;
      }
      p {
        display: none;
      }
    }

    .tab-content {
      visibility: visible;
    }
  }

  .tab-header {
    position: relative;
    padding: 35px 0 0;

    .container {
      position: relative;

      @media (max-width: 767px) {
        padding-right: 75px;
      }
    }

    h1 {
      font-size: 25px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    p {
      font-size: 14px;
      max-width: 600px;
    }

    .toggle-button {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background-color: #dbdbdb;
      position: absolute;
      right: 15px;
      top: -15px;
      cursor: pointer;

      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 54%;
        transform: translateY(-50%);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }

  .tab-content {
    visibility: hidden;

    .ChargingMap {
      .map-content-top {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
        .toggle-button {
          width: 25px !important;
          height: 25px !important;  
          top: 3px !important;
          img {
            width: 15px;
            height: 10px;
            top: 56% !important;
          }
        }
      }
}

/* Client Provided CSS */
section.container.ev-jumbotron {
  background-color: #F2F2F2 !important;
}
.tab-section .tab-header .toggle-button {
  background: #fff;
  box-shadow: 0 3px 9px -2px rgba(21, 70, 45, 0.24);
  -webkit-transition: box-shadow 200ms ease-out, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease-out, -webkit-transform 200ms ease;
  transition: transform 200ms ease, box-shadow 200ms ease-out;
  transition: transform 200ms ease, box-shadow 200ms ease-out,
    -webkit-transform 200ms ease;
}
.tab-section .tab-header .toggle-button:hover {
  box-shadow: 0 7px 15px -2px rgba(21, 70, 45, 0.24);
}
.PricePanels {
  border: none;
  background-color: transparent !important;
  
}
.PanelItem {
  background-color: #fff !important;
  border: 2px #E4E4E4 solid !important;
  padding: 30px 15px 15px 15px !important;

  .RenderItemTitle.bottom-margin-small{
    margin-bottom: 20px !important;
  }

}

.ev-jumbotron .ev-pricepanel-buttons .btn {
  padding: 12px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px -1px rgba(6, 49, 28, 0.25);
  -webkit-transition: border-radius 200ms ease-out, box-shadow 200ms ease-out;
  transition: border-radius 200ms ease-out, box-shadow 200ms ease-out;
  color: #4d4d4d;
  font-weight: 600;
  text-decoration: none;
  border: none;
}
.ev-jumbotron .ev-pricepanel-buttons .btn:hover {
  border-top-left-radius: 0px;
  box-shadow: 0 1px 4px -2px rgba(5, 59, 120, 0.38);
  color: #03783d;
}