.MapControlPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right:10px;

  @media(max-width:768px) {
    padding:0 0 25px;
  }

  .map-controls {
    position: relative;
    flex-grow: 1;
    height:100%;

    .input-group {
      width:100%;
      margin:0 !important;
    }

    .btn-ae {
      padding: 12px 12px;
      border-radius: 3px;
      font-size:16px;
    }
  }

  .SelectedChargingStationCard {
    margin-bottom: 10px;
  }

  .input-group {
    label {
      width:100%;
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size:12px;
      margin:0px 0px 5px;
    }

    #input-zipcode-for-charging-map {
      border-radius: 0.25rem !important;
      padding:1.5rem 0.8rem;
      font-size:16px;
      font-weight: bold;
    }
  }

  #update-zipcode-button {
    position: absolute;
    right: 5px;
    top: 26px;
    background-color: #64A70B;
    width: 44px;
    height: 44px;
    border: none;

    img {
      position: relative;
      top:-1px;
      right:2px;
    }
  }

  .form-check label {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 0;

  }
}
