@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-map-container-border;
		// border-bottom-left-radius: 5px;
		// border-bottom-right-radius: 5px;
		display: flex;

		@media(max-width:767px) {
			flex-wrap: wrap;
			height:auto;
		}
	}
	.sidebar-container {
		width:240px;
		position: relative;
		padding-right:25px;

		@media(max-width:767px) {
			width:100%;
		}

		.form-inline {
			.input-group {
				width:100%;
				display:block !important;
				margin:0 !important;

				label {
					display: block;
					color:#000;
					text-transform: uppercase;
					font-size:12px;
					margin:0 0 5px 0;
				}

				input#input-zipcode-for-charging-map {
					width:100%;
					border-radius: 5px;
					padding:27px 10px;
				}
			}

			.btn {
				width:44px;
				height:44px;
				background: #64A70B;
				border-radius: 2px;
				position: absolute;
				top:29px;
				right:31px;
				border:none;
				z-index: 9;

				img {
					position: relative;
					left:-3px;
				}
			}
		}
	}

	.Map {
		width:calc(100% - 240px) !important;
		height:auto !important;

		@media(max-width:767px) {
			width:100% !important;
			height:500px !important;
		}
	}

	.legend {
		width:100%;
		background: $ChargingMap-legend-background;
		border: $ChargingMap-legend-border;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		// margin-bottom: 10px;

		@media(max-width:767px) {
			position: relative;
			bottom: -110px;
			z-index: 999999;
		}

		&.map-legend {
			bottom:0px;

			@media(max-width:767px) {
				position: relative;
				bottom:-50px;
			}
		}

		.charger-type {
			position: relative;
			padding-right: 2rem;
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 30px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}
}
.DealerMap {
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-dealer-map-container-border;
		border-radius: $ChargingMap-dealer-map-container-border-radius;
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-dealer-legend-background;
		border: $ChargingMap-dealer-legend-border;
		border-radius: $ChargingMap-dealer-legend-border-radius;
		padding: 15px;
		font-size: 0.75rem;
		max-width: 199px;
		margin-bottom: 10px;
		position: relative;
		padding-right: 2rem;
		margin-bottom: 1rem;
		.charger-type {
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 30px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}
}
.Map {
	overflow: hidden;
}

.map-content {
	border-radius: 3px;
	border: 1px solid #dee2e6;
	box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
	background-color: #ffffff;
	// padding: 1rem 1.5rem;
	border-radius: 0 0 3px 3px;
	position: relative;
	display:flex;
	padding:1.5rem;

	@media(max-width: 768px) {
		flex-wrap: wrap;
	}
	
	.col-sm-4, .col-sm-8 {
		padding:0;

		@media(max-width: 768px) {
			width: 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

}

.locationCardHolder {
	margin-top: 20px;
}
