@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;

	.top-nav .container {
		max-width: none;
		padding: 0 30px;
	}

	.non-click {
		pointer-events: none !important;
	}
	.main-nav .container {
		max-width: none;
		padding: 0 100px;
		height: 100%
	}
	
	.flex {
		display: flex !important;
	}
	.block {
		display: block !important;
	}
	.hidden {
		display: none !important;
	}

	.selected {
		background-color: "#f2f2f2";
		border-right: "3px solid #0072ce";
	}

	a {
		font-family: 'Open Sans';
		color: $Header-link-color;
		font-size: 16px;
		font-weight: 400;
	}

	@media(max-width:768px) {
		.main-nav .container {
			padding: 0;

			.collapseContainer {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				padding: 0 1rem;
			}

			.navbar-collapse {
				height: calc(100vh - 110px);
				background-color: white;
				margin-top: 18px;

				&.show {						
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;

					.navbar-nav {
						display: flex;
						justify-content: flex-start;
						height: 100% !important;
						background-color: white;

						li {
							width: 100vw;
							max-height: 59px;
							
							margin: 0;
							padding: 0 1rem 0 1rem;
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;

							a {
								font-family: 'Open Sans';
								margin: 0;
								font-size: 18px;
								font-weight: 500;
							}

							img {
								display: flex;
								height: 18px;
							}

							.navigation-mega-menu {
								margin: 126px 0 0;
								width: 100%;
								right: 0;

								.navigation-container {
									margin-left: 0 !important;
									padding: 0 1rem;
									min-height: calc(100vh - 110px);

									.navigation-header {
										display: flex;
										align-items: center;
	
										.mega-nav-title {
											font-family: 'Open Sans';
											color: #000;
											font-size: 24px;
											font-weight: 600;
										}
									}
	
									.container-sub-list {
										ul:nth-child(1) {
											display: flex;
										}
	
										ul {
											display: none;
											flex-direction: column;
											border: none;
	
											li {
												max-width: 100%;
												padding: 0;
												height: 56px;

												border: none;
	
												background-color: white !important;
	
												&.desktop {
													display: none;
												}
	
												&.mobile {
													display: flex;
												}
	
												.mega-nav-secondary-list-item-title {
													font-family: 'Open Sans';
													color: #363A36;
													font-size: 16px;
													font-weight: 500;
												}
	
												.mega-nav-secondary-list-item-description {
													display: none;
												}
											}
	
											.mega-nav-tertiary-list.target-mobile {
												padding: 0 0 0 3rem;

												.mega-nav-tertiary-list-item {
													height: auto;
													max-height: fit-content;
													margin-top: 2rem;

													a {
														.mega-nav-tertiary-list-item-title {
															font-family: 'Open Sans';
															color: #000;
															font-size: 16px;
														}

														.mega-nav-tertiary-list-item-description {
															font-family: 'Open Sans';
															font-size: 16px;
															color: #4d5154;
    														font-weight: 400;
														}
														
													}
													
												}
											}
										}
									}
								}
							}
						}
					}
				}

				ul {
					display: none;
				}
			}
		}
	}	

	.navbar {
		display: flex;
		align-items: flex-end;
		padding: 0 0 1rem;
		height: 100%;

		.navbar-brand {
			padding: 0.75rem 0;
			position: relative;
			top: 0;
			left: 0;
			padding-top: 0;
			padding-bottom: 0;
			z-index: 9;

			img {
				width: 200px;
			}
		}

		.navbar-collapse {
			height: 100%;

			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}

			.navbar-nav {
				align-items: start;
			}

			.dropdown-item {
				border-radius: 0;
			}
		}

		.btn-secondary {
			background-color: white !important;
			border: none;

			&.hover {
				background-color: white !important;
				border: none
			}
		}

		@media (max-width: 1000px) {
			.navigation-mega-menu {
				border: none !important;
				top: -15px !important;
				
				left: 0 !important;


				.navigation-container {
					margin-left: 25% !important;
					border-bottom: 1px solid black;
					.navigation-header {
						display: flex !important;
					}
				}
				.left-arrow {
					display: block !important;
					transform: rotate(180deg) scale(0.8);
					margin-right: 20px;
					margin-top: -3px;
				}
			}	
		}
		.left-arrow {
			display: none;
		}
		.navigation-mega-menu {
			position: absolute;
			top: 106px;
			left: -100px;
			max-width: 100vw;
			width: 100vw;
			height: auto;
			background-color: #fff;
			border-top: solid 1px #e5e5e5;
			border-bottom: solid 1px #e5e5e5;
			z-index: 99;


			.navigation-container {
				max-width: 1400px;
				padding: 0 0 0 36px;
				display: block;
				margin: auto;

				.mega-nav-title-bar {
					display: flex;
					justify-content: space-between;
					border-bottom: none;
					align-items: center;

					.mega-nav-title {
						margin: 20px 0px 20px 0px;
						font-family: 'Open Sans';
						font-size: 1.5rem;
						font-weight: 600;
						color: #000000;
					}
				}

				
				@media (max-width: 1000px) {
					.container-sub-list {
						display: inline !important;
						.mega-nav-secondary-list { 
							margin-bottom: 20px !important;
						}
					}
				}
				.container-sub-list {
					text-align: left;
					display: none;
					flex-direction: row;



					ul:nth-child(2) {
						display: flex;
					}
					.mega-nav-secondary-list {
						border-right: solid 1px #e5e5e5;
						flex: 0 0 420px;

						.mega-nav-secondary-list-item {
							margin-top: 10px;
							display: flex;
							justify-content: space-between;

							&.mobile {
								display: none;
							}

							&:hover {
								border-right: solid 3px #0072ce;
								background-color: #f2f2f2;
								@media (max-width: 1000px) {
										.right-arrow {
											transform: rotate(90deg);
										}
									}
							}

							&.active {
								border-right: solid 3px #0072ce;
								background-color: #f2f2f2;
								@media (max-width: 1000px) {
										.right-arrow {
											transform: rotate(-90deg);
										}
									}
							}

							.right-arrow {
								margin-right: 20px;
							}

							.mega-nav-secondary-list-item-title {
								font-family: 'Open Sans';
								font-size: 18px;
								color: #212529;
								font-weight: 600;
							}

							.mega-nav-secondary-list-item-description {
								font-family: 'Open Sans';
								font-size: 1rem;
								font-weight: 500;
								color: #4d5154;
								margin-top: .36em;
							}

							
							@media (max-width: 1000px) {
								.right-arrow {
									transform: rotate(90deg);
								}
							}
						}
					}

					.hidden {
						display: none !important;
					}
					.mega-nav-tertiary-list {
						display: none;
						flex-direction: row;
						flex-wrap: wrap;
						align-content: flex-start;
						width: 100%;

						&.mobile {
							display: none;
						}

						@media (max-width: 1000px) {
							.mega-nav-tertiary-list-item {
								padding: none !important;
							}
						}
						.mega-nav-tertiary-list-item {
							padding: .5rem 3rem;
							flex: 50%;

							.mega-nav-tertiary-list-item-title {
								font-family: 'Open Sans';
								font-size: 1rem;
								font-weight: 400;

							}

							.mega-nav-tertiary-list-item-description {
								font-family: 'Open Sans';
								font-size: .9rem;
								font-weight: 400;
								color: #4d5154;
							}
							&:hover {
								.mega-nav-tertiary-list-item-title {
									color: #579EDB;
									text-decoration: underline #579EDB;
									text-underline-position: under;
								}

								.mega-nav-tertiary-list-item-description {
									text-decoration: underline;
								}

								text-underline-position: under;
							}
						}
					}

					.mega-nav-secondary-list,
					.mega-nav-tertiary-list {
						padding: 0;
						margin: 0;
						list-style: none;
					}
				}
			}
		}
	}

	.nav-item {
		margin: 5px;
	}

}

.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}

a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}

	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}

	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}

.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color: $Header-navbar-toggler-color;

	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(100,167,11, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			align-items: center;

			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					text-decoration: none;
				}

				a.active {
					font-weight: bold;
				}
			}
		}
	}
}



.Header {
	height: 110px;

	.top-nav {
		padding: 0px 0;
		background-color: #f0f0f0;
		border-bottom: 2px solid #F2F2F2;

		@media(max-width:991px) {
			padding: 0;
		}

		.navbar .navbar-nav {
			width: 100%;
			display: block;
			text-align: left;
			top: 1px;
			margin-bottom: -1px;

			li {
				display: inline-block;
				margin: 0;

				@media(max-width:768px) {
					display: block;
				}

				a {
					display: block;
					padding: 1rem 2rem;
					border-radius: 0px;

					&.active {
						background-color: #fff;

						span {
							color: #363A36;
						}
					}

					&:hover {
						span {
							color: #868986;
						}

						&.active {
							span {
								color: #000;
							}
						}
					}

					span {
						font-family: 'Open Sans';
						line-height: 1em;
						font-size: 16px;
						font-weight: 600;
						color: #000000;
					}
				}
			}
		}
	}

	.main-nav {
		border-bottom: 2px solid #F2F2F2;
		height: 100%;

		@media(max-width:1000px) {
			.navbar-nav {
				z-index: 99;
				top: 100px !important;
				height: 300px !important;
				background-color: white;
			}
		}
		.navbar-nav {
			width: 100%;
			display: block;
			top: 2px;

			height: 100%;
			display: flex;
			justify-content: flex-end;

			.nav-item {
				text-decoration-color: #0072CE;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;


				.hoverBottom {
					position: relative;
			
					&::after {
					  content: "";
					  position: absolute;
					  bottom: 0;
					  left: 0;
					  right: 0;
					  height: 0;
					  background-color: #0072ce;
					  transition: all ease 0.1s;
					}
			
					&:hover::after {
					  height: 6px;
					}
				}
			}

			li {
				display: inline-block;

				@media(max-width:768px) {
					display: block;
				}
			}
		}
	}
}