#carDetailsGrid {
  .gridTitle {
    font-weight: 600;
    font-size: 30px;
  }
  .grid {
    display: grid;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    .row {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      .column {
        padding: 15px 15px 15px 15px;
        width: 100%;
      }
      @media (max-width: 555px) {
        grid-template-columns: 35% 15% 35% 15%;
      }
    }
    @media (max-width: 415px) {
    .row {
        display: flex;
        justify-content: center;
        .column {
          width: 150px;
          background-color: white;
          border: none;
          border-top: 1px solid gray;
        }
        .data {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .flex {
    display: flex;
  }
  .title {
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .data {
    font-size: 16px;
  }
  .grey {
    background-color: #f7f7f7;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .white {
    background-color: #ffffff;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .bordered {
    border: 1px solid lightgray;
  }
}