.upcoming-ev-price-details {
       .main {
              width: 100%;
       }
}

.upcoming-ev-price-details.container {
       padding-right: 25px;
       padding-left: 25px;
}

.ev-jumbotron {
       padding: 50px 0 25px;
}