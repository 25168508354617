@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
	// background-color: #F2F2F2;

	.compare-title {
		background-color: #F2F2F2;
		height: 550px;
		margin-bottom: -390px;

		@media(max-width:767px) {
			margin-bottom: 0;
			height:auto;
		}

		h2 {
			padding:10px 0 15px;
		}

		a {
			color:#477A06;
		}
	}

	.compared-vehicle-container {
		padding-top: 20px;
		background-color: transparent;

		.ComparedVehicle {
			.form-group {
				label {
					text-transform: uppercase;
					font-size:12px;
				}
			}
		}
	}

	.single-vehicle {
		padding-top: 20px;
		padding-bottom: 30px;
	}

	.ComparedCarDetails {
		a.btn {
			background-color:#fff;
			color:#477A06;
			border: 1px solid #DBDBDB;
			border-radius: 3px;
		}
	}

	#cost-of-ownership-section, #speed-of-charge-section, #electric-range-section {
		background-color: #F2F2F2;

		.container {
			background-color: transparent;

			p.h1 {
				position: relative;
				top:35px;
			}

			.assumptions-area {
				position: relative;
				top:50px;

				.RenderItemTitle {
					color:#000;
				}
			}
		}
	}

	#electric-range-section {
		padding-bottom: 50px;
	}
}

.electric-range-graph {
	.show-full-list-container {
              text-align: center;
              margin:20px 0 0;

              button {
                     background-color:#fff;
              }
       }
}
