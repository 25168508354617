@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
}

.Footer {
	padding:50px 0;

	.footer-col {
		width:20%;

		@media(max-width:991px) {
			width:100%;
			margin-bottom: 30px;
		}

		.footer-logo {
			img {
				width:140px;
			}
		}

		h6 {
			text-transform: uppercase;
			color:#fff;
			font-size:12px;
		}

		.textwidget {
			p {
				color:#fff;
				line-height: 1.8em;
			}
		}

		ul.social {
			max-width: 130px;
			margin-top: 0;
			margin-bottom: 1.5rem;
			line-height: 2;

			li {
				display: inline;
				padding-right: 1.15rem;
				line-height: 2.85;

				img {
					height: 1.5rem;
					width: auto;
					display: inline-block;
				}
			}
		}

		ul {
			list-style-type: none;
			padding:0;
			margin:0;

			li {
				a {
					color:#fff;

					&:hover {
						color:#64A70B;
					}
				}
			}
		}
	}
}
.disclaimer_container {
	text-align: left;
	margin-top: 10px;
	padding-left: 0px;
	font-family: Arial, Helvetica;
	

	font-size: 10px; /* Set the font size to 10px */
	div {
		   background-color:transparent !important;

		   img {
				  max-width: 100%;
				//   margin:0 0 25px;
		   }

		   p {
				  color:white;
		   }
	}
}
