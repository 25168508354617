@import "src/client_customizations/scss/client_master.scss";

/* evc-card */
/* Card Layouts */

body {
	font-family: 'sharp sans';
}

h1 {
	font-weight: 600;
}
h2 {
	font-weight: 600;
}
h3 {
	font-weight: 600;
}
h4 {
	font-weight: 600;
}
h5 {
	font-weight: 600;
}
.h1 {
	font-weight: 600;
}
.h2 {
	font-weight: 600;
}
.h3 {
	font-weight: 600;
	strong {
		color: $PageLayout-header-bold-color;
	}
}
.h4 {
	font-weight: 600;
	strong {
		color: $PageLayout-header-bold-color;
	}
}
.h5 {
	font-weight: 600;
}
strong {
	font-weight: 600;
}
a {
	&:focus {
		// box-shadow: $PageLayout-link-focus-box-shadow;
		outline: 0;
	}
}
button, .btn {
	&:focus {
		box-shadow: $PageLayout-button-focus-box-shadow;
		outline: 0;
	}
}
.form-control {
	&:focus {
		// box-shadow: $PageLayout-form-control-focus-box-shadow;
		box-shadow: none;
		border-color: #64A70B;
		outline: 0;
	}
}
input {
	&:focus {
		// box-shadow:$PageLayout-input-focus-box-shadow;
		box-shadow: none;
		outline: 0;
	}
}
select {
	&:focus {
		// box-shadow: $PageLayout-select-focus-box-shadow;
		box-shadow: none;
		outline: 0;
	}
}
textarea {
	&:focus {
		box-shadow: $PageLayout-textarea-focus-box-shadow;
		outline: 0;
	}
}
.image-gallery-thumbnail {
	&:focus {
		box-shadow:$PageLayout-image-gallery-thumbnail-focus-box-shadow;
		outline: 0;
	}
}
.SimpleCopyPageBody {
	.card {
		&:focus {
			box-shadow: $PageLayout-SimpleCopyPageBody-card-focus-box-shadow;
			outline: 0;
		}
		background-color:$PageLayout-SimpleCopyPageBody-card-background-color;
		&:hover {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		}
		&:-webkit-any-link {
			&:hover {
				color:$PageLayout-SimpleCopyPageBody-card-link-hover-color;
				text-decoration: none;
			}
		}
		a {
			&:hover {
				color: $PageLayout-SimpleCopyPageBody-card-link-hover-color;
				text-decoration: none;
			}
		}
	}
	padding-bottom: 400px;
	p.h4 {
		color: $PageLayout-SimpleCopyPageBody-card-header-color;
		margin-top: 30px;
		font-weight: 500;
	}
}
.evc-card {
	&:focus {
		box-shadow: $focus-box-shadow;
		outline: 0;
	}
	background-color: $PageLayout-evc-card-focus-box-shadow;
	font-size: $PageLayout-evc-card-font-size;
	border: $PageLayout-evc-card-border;
	border-radius: 4px;
	color:$PageLayout-evc-card-color;
	width: 100%;
	display: inline-block;
	cursor: pointer;
	&:hover {
		border: $PageLayout-evc-card-hover-border;
		box-shadow: $PageLayout-evc-card-hover-box-shadow;
		color: $PageLayout-evc-card-hover-color;
		text-decoration: none;
	}
}
.SocialMediaShareButton {
	&:focus {
		box-shadow: $PageLayout-socialmedia-focus-box-shadow;
		outline: 0;
	}
}
.clickable-faq {
	&:focus {
		box-shadow: $PageLayout-faq-focus-box-shadow;
		outline: 0;
	}
	color: $PageLayout-faq-focus-color;
	display: block;
	text-decoration: none;
	padding: 1rem;
	cursor: pointer;
}
.evc-card.selected {
	box-shadow: none;
}
#logo {
	&:focus {
		box-shadow: none;
	}
}
.PageLayout {
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}
section.container {
	padding: 2rem 1rem;
	background-color: $PageLayout-container-background-color;
}
.hide-offscreen {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
}
.clear-bottom-margin {
	margin-bottom: 0 !important;
}
.small-bottom-margin {
	margin-bottom: 5px !important;
}

.legal-disclaimer {
	p {
		color: $PageLayout-legal-disclaimer-color;
		text-align: center;
		margin:15px 0;
		font-size: 10px;
	letter-spacing: 0;
	}
}

.sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 50px;
}
.renderRowOfData {
	p {
		span {
			float: right;
			font-weight: 700;
		}
	}
}
.h6 {
	strong {
		color: $PageLayout-header-strong-color;
	}
}
.SimpleCopyPageHeader {
	color: $PageLayout-SimpleCopy-color;
}
a.supporting-link {
	color: $PageLayout-SimpleCopy-color;
	margin-top: 0;
	font-weight: 700;
	font-size: 12px;
}
.evc-card.not-selected {
	height: 200px;
}
a.evc-card {
	&:hover {
		border: $PageLayout-evc-card-hover-border;
		box-shadow:$PageLayout-evc-card-hover-box-shadow;
		color: $PageLayout-evc-card-hover-color;
		text-decoration: none;
	}
}
.render-cards-container {
	display: flex;
	flex-wrap: wrap;
	.evc-card {
		width: 48%;
		margin: 1%;
	}
}
@media (min-width: 768px) {
	section.container {
		padding: 2rem 2rem;
	}
}
@media (min-width: 992px) {
	section.container {
		padding: 2rem 3rem;
	}
	.render-cards-container {
		.evc-card {
			width: 49%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
        			margin-left: 0;
			}
			&:first-child {
				margin-top: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
		}
	}
	.render-cards-container.full-width {
		.evc-card {
			width: 23.5%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(4n) {
				margin-right: 0;
			}
			&:nth-of-type(4n +1) {
        margin-left: 0;
			}
		}
	}
}
@media (min-width: 1200px) {
	.evc-card.not-selected {
		height: 220px;
	}
	.render-cards-container {
		.evc-card {
			width: 32%;
			margin: 1%;
		}
		>* {
			&:nth-of-type(3n) {
				margin-right: 0;
			}
			&:nth-of-type(3n+1) {
					margin-left: 0;
      }			
			&:first-child {
				margin-top: 0;
			}
			&:nth-child(2) {
				margin-top: 0;
			}
			&:nth-child(3) {
				margin-top: 0;
			}
		}
	}
}
