@import "src/client_customizations/scss/client_master.scss";

input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;

  /*fix for FF unable to apply focus style bug */
  border: $InputRange-border;

  margin-top: 5px;
  border-radius: 5px;
  padding: 9px 0;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: $InputRange-runnable-track-background;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: $InputRange-slider-thumb-border-radius;
  background: $InputRange-slider-thumb-background;
  margin-top: -6px;
  cursor: pointer;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $InputRange-focus-runnable-track-background;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  background: $InputRange-runnable-track-background;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 20px;
  width: 20px;
  border-radius: $InputRange-slider-thumb-border-radius;
  background: $InputRange-slider-thumb-background;
  cursor: pointer;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: $InputRange-fill-lower-background;
  border-radius: $InputRange-fill-lower-radius;
}
input[type="range"]::-ms-fill-upper {
  background: $InputRange-fill-upper-background;
  border-radius: $InputRange-fill-upper-radius;
}
input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: $InputRange-ms-thumb-radius;
  background: $InputRange-ms-thumb-background;
}
input[type="range"]:focus::-ms-fill-lower {
  background: $InputRange-ms-fill-lower;
}
input[type="range"]:focus::-ms-fill-upper {
  background:$InputRange-ms-fill-upper;
}
