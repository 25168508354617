@import "src/client_customizations/scss/client_master.scss";

.TabbedMaps {
  // .tabbed-maps-panel {
  //   border-radius: 3px;
  //   border: 1px solid #dee2e6;
  //   box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
  // }
  .tab-content {
    background-color: #ffffff;
    // padding: 1rem 1.5rem;
    border-radius: 0 0 3px 3px;
    position: relative;
    top: -1px;
  }
  .nav-tabs {
    box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
    border-radius: 3px 3px 0 0;
    background-color: #ffffff;
    padding: 0;
    border-top:1px solid #dee2e6;
    border-right:1px solid #dee2e6;
    border-left:1px solid #dee2e6;
    padding:0 1.5rem;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      cursor: pointer;
      border-radius: 0;
      padding: 1.5rem 0 1.3rem;
      font-style: normal;
      font-weight: 600;
      margin: 0 1rem;
      border: none;
      font-size:16px;

      &.active {
        color:#000;
      }
    }

    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      // color: $red;
      border-bottom:5px solid #64A70B;
    }

  }
}
