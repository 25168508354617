    #HomepageVehiclesCarousel {
        background-color: #F2F2F2 !important;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    #vehicleRender {
        .button-box {
            background: #fff;
            box-shadow: 0 5px 10px -2px rgba(61, 73, 67, 0.15);
            transition: transform 200ms ease, box-shadow 200ms ease-out;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            position: absolute;
            top: 150px;
            cursor: pointer;
        }

        .left-arrow {
            position: absolute;
            top: 12px;
            left: 15px;
            height: 50%
        }

        .right-arrow {
            position: absolute;
            top: 12px;
            left: 20px;
            height: 50%
        }

        .carousel-card {
            background-color: white;
            margin-left: 25px;
            margin-right: 25px;
            width: 250px;
        }

        .render-cards {
            display: flex;
            flex-wrap: wrap;
        }

        .carousel-wider {
            margin-top: 30px;
            width: 31.5%;
            height: auto;
            margin: 20px;
        }

        @media(max-width: 767px) {
            .carousel-wider {
                width: 165px;
                margin: 40px -25px 0px 20px;
            }

        }

        @media(max-width: 992px) {
            .carousel-wider {
                width: 200px;
            }

        }

        @media(max-width: 1196px) {
            .button-box {
                top: 125px;
                left: 30px;
            }
        }

        .render-cards-container {
            flex-wrap: nowrap;
        }
    }